<template>
<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.85 200">
  <path class="cls-1" d="M31.88,200v-87.05H0v-36.92h31.88v-27.23C31.88,17.2,51.18,0,79.36,0c13.5,0,25.11,1.01,28.49,1.45v33.02h-19.55c-15.33,0-18.3,7.29-18.3,17.98v23.57h36.56l-4.76,36.92h-31.8v87.05H31.88Z"/>
</svg>
</template>

<script>
export default {
  name: "svgIconFacebook"
}
</script>

<style scoped>
.cls-1{fill:#e9e9e9;stroke-width:0px;}
</style>
