import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView";
import TextView from "@/views/TextView";
import LineupView from "@/views/LineupView";
import InfoView from "@/views/InfoView";
import ErrorView from "@/views/ErrorView";
// import TimetableView from "@/views/TimetableView";
import TimetableFancyView from "@/views/TimetableFancyView";

const routes = [
    {path: '/', name: 'home', component: HomeView},
    {path: '/lineup', name: 'lineup', component: LineupView},
    {path: '/info', name: 'info', component: InfoView},
    {path: '/timetable', name: 'timetable', component: TimetableFancyView},


    {path: '/kontakt', name: 'kontakt', component: TextView},
    {path: '/impressum', name: 'impressum', component: TextView},

    { path: '/:pathMatch(.*)*', name: 'error', component: ErrorView},

    // { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        /*Scroll To Saved Position*/
        if (savedPosition) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        top: savedPosition.top,
                        behavior: 'smooth',
                    })
                }, 400)
            })
        }
        /*Scroll To Hash*/
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 80,

            }
        }
        /*Scroll To Top (Default)*/
        return {
            top: 0,
            behavior: 'smooth',
        }
    },

})

router.beforeResolve(() => {
})
router.beforeEach(() => {
    window.scrollTo(0, 0);
});

export default router
