<template>
  <h2 class="section-title">Page not Found</h2>
  <div class="container">
    <div class="content text">Die gewünschte Seite existiert nicht.</div>

  </div>
</template>

<script>

export default {
  name: "ErrorView",
  data() {
    return {
      title: '',
      text: '',
    };
  },
  methods: {},
  computed: {},
  created() {

  },
}
</script>

<style scoped>

</style>
