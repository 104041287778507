import { createApp } from 'vue'
import App from './App.vue'

/**
 * create App
 * @type {App<Element>}
 */
const benigeier = createApp(App);

/**
 * Use Plugins and other helpful stuff
 */
//Vue Router & VUEX Store
import router from "@/router";
import {store} from "@/store";

benigeier.use(router);
benigeier.use(store);

benigeier.mount('#app')