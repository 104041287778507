<template>
  <h2 class="section-title">Info</h2>



    <div class="artist-wrapper expand-wrapper" v-for="(info, i) in this.infos" :key="i" :ref="info.title">
      <div class="artist-title expand-title" v-html="info.title" @click="toggleExpandBox(info.title)"></div>
      <div class="container-fluid">
        <div class="artist-content expand-content">
          <div class="expand-content-inner">
            <div class="container">
              <div v-if="info.text" class="text mb-1" v-html="info.text"/>
              <div class="" v-if="info.image && info.download">
                <a :href="getAssetUrl(info.download.path)" target="_blank">
                <img class="download" :src="getAssetUrl(info.image.path)">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<PartFishParallax />



</template>

<script>
import {cpFullStorageUrl, cpGetContentTree} from "@/api/cockpit";
import PartFishParallax from "@/components/PartFishParallax";

export default {
  name: "InfoView",
  components: {PartFishParallax},
  data() {
    return {
      infos: [],
    };
  },
  methods: {
    getAssetUrl(path) {
      return cpFullStorageUrl(path);
    },
    toggleExpandBox(ref) {
      //Expand Wrapper Element
      let expandWrapperEl = this.$refs[ref];
      if (typeof this.$refs[ref][0] !== "undefined") {
        expandWrapperEl = this.$refs[ref][0];
      }

      //Close opend Expand Wrapper
      let openArtistWrapper = document.querySelectorAll('.artist-wrapper.opened');
      openArtistWrapper.forEach(element => {
        if (element != expandWrapperEl) {
          element.classList.remove('opened');
        }
      });

      //open clicked wrapper
      setTimeout(() => {
        expandWrapperEl.classList.toggle('opened');
      }, 0);

      //scroll Into View
      setTimeout(() => {
        let elementPosition = expandWrapperEl.getBoundingClientRect().top + window.pageYOffset;
        // An die Ziel-Position scrollen
        window.scrollTo({
          top: elementPosition - 150,
          behavior: 'smooth'
        });
      }, 500);
    },
    // getArtistImageUrl(image) {
    //  return cpFullStorageUrl(image);
    // },
    // getWrittenDay(dateString, withYear) {
    //   let date = new Date(dateString);
    //
    //   // Definiere die Wochentage und Monate auf Deutsch
    //   let days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    //   let months = [
    //     'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    //     'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    //   ];
    //
    //   // Holen den Tag und Monat
    //   let dayName = days[date.getDay()];
    //   let day = date.getDate();
    //   let month = months[date.getMonth()];
    //   let year = date.getFullYear();
    //
    //   // Formatieren des Datums
    //   return withYear ? `${dayName} ${day}. ${month} ${year}` : `${dayName} ${day}. ${month}`;
    // },
    // getWrittenVenue(timeString) {
    //   if (typeof timeString[0] !== "undefined") {
    //     return timeString[0]
    //   }
    //   return '';
    // }
  },
  computed: {
    // getLineupByDate() {
    //   return this.lineup.reduce((acc, currentItem) => {
    //     const date = currentItem.date;
    //     if (!acc[date]) {
    //       acc[date] = [];
    //     }
    //     acc[date].push(currentItem);
    //     return acc;
    //   }, {});
    // },
    // getLineupByDateSorted() {
    //   const lineup = this.getLineupByDate
    //   const sorted = {};
    //   for (const date in lineup) {
    //     sorted[date] = [...lineup[date]].sort((a, b) => {
    //       return a.playTime.localeCompare(b.playTime);
    //     });
    //   }
    //   return sorted;
    // }


  },
  created() {
    cpGetContentTree('info').then(data => {
      this.infos = data;
    });
  },
}
</script>

<style scoped>
img.download {
  width: 100%;
}
.artist-image-wrapper {
  display: flex;
  justify-content: center;
}

.artist-image {
  max-width: 700px;
  width: 100%;
}
</style>
