<template>
<div class="background-wrapper">

  <div class="d-none d-lg-block background background-image bgp-cc " v-bind:style="{backgroundImage: 'url(' + getHeadingSvgUrl + ')'}">
<!--    <a :href="this.link" :target="this.target">-->
<!--      &lt;!&ndash;    <img class="image" v-bind:style="{backgroundImage: 'url(' + this.imageUrl + ')'}" />&ndash;&gt;-->
<!--    </a>-->

  </div>
  <img v-bind:src="getHeadingSvgUrl" class="d-lg-none"/>



  </div>
  <div class="margin"/>
  <LineupView />
</template>

<script>
import {cpGetContentItem, cpFullStorageUrl} from "@/api/cockpit";
import LineupView from "@/views/LineupView";


export default {
  name: "HomeView",
  components: {LineupView},
  data() {
    return {
      headingSvgUrl: '',
    };
  },
  computed: {
    getHeadingSvgUrl() {
      return this.headingSvgUrl;
    }
  },
  created() {
    cpGetContentItem('global').then(data => {
      this.headingSvgUrl = cpFullStorageUrl(data.heading.path);

      // console.log(data);
      // if(data.image) {
      //   this.imageUrl =
      // }
      // this.link = data.link;
      // this.target = data.target;
    });

  },
}
</script>

<style scoped>
.background-wrapper {
  padding: var(--main-margin);
  height: calc(100vh - var(--header-height));
  background-color: var(--color-orange)

}
.background {
  /*background: red;*/
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

img {
  margin: 0 0;
}

.margin {
  margin: 0;
  margin-bottom: 0.5rem;
}

@media (max-width: 992px) {
  .background-wrapper {
    height: initial;
  }
}
</style>
