<template>
  <section class="wrapper">
    <h2 class="subtitle">ÖV-Anreise ist im Ticket inbegriffen</h2>
    <h3>ÖV-Partnerinnen</h3>
    <div class="partnerinlogo-wrapper">
      <a class="partnerinlogo" href="https://www.aargauverkehr.ch/" target="_blank">
        <SvgLogoAva/>
      </a>
      <a class="partnerinlogo" href="https://www.a-welle.ch/" target="_blank">
        <SvgLogoAwelle/>
      </a>
    </div>
  </section>
</template>

<script>
import SvgLogoAva from "@/components/svg/SvgLogoAva";
import SvgLogoAwelle from "@/components/svg/SvgLogoAwelle";

export default {
  name: "SectionParterin",
  components: {SvgLogoAwelle, SvgLogoAva}
}
</script>

<style scoped>
.wrapper {
  background-color: var(--color-blue);
  padding: 2rem;
  box-sizing: border-box;
  text-align: center;
}

h2, h3 {
  /*font-size: 3rem;*/
  color: var(--color-grey);
  margin-bottom: 1rem !important;
}

.partnerinlogo-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 0 2rem;
}

svg {
  width: 150px;
  fill: var(--color-grey);
  transition: all ease var(--transition-time);
}
svg:hover {
  fill: var(--color-orange);
}

@media (max-width: 576px) {

  .partnerinlogo-wrapper {
    display: flex;
flex-direction: column;
    gap: 1rem;
  }
  svg {
    width: 200px;
  }
}

</style>
