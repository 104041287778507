<template>
  <h2 class="section-title">Line up</h2>
  <div class="day-wrapper" v-for="(day, d) in getLineupByDateSorted" :key="d">

    <h2 class="section-title date">{{ getWrittenDay(d, true) }}</h2>

    <div class="artist-wrapper expand-wrapper" v-for="(artist, a) in day" :key="a" :ref="artist.name">
      <div class="artist-title expand-title" v-html="artist.name" @click="toggleExpandBox(artist.name)"></div>
      <div class="container-fluid">
        <div class="artist-content expand-content">
          <div class="expand-content-inner">
            <h2 class="subtitle">
              {{ getWrittenDay(d, true) }} •
              {{ artist.playTime }} •
              {{ artist.venue }}
            </h2>
            <h3 v-html="artist.mood"/>
            <div class="container">
              <div class="text" v-html="artist.text"/>
              <div class="artist-image-wrapper">
                <img class="artist-image" :src="getArtistImageUrl(artist.image.path)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PartFishParallax/>
</template>

<script>
import {cpGetContentItems, cpFullStorageUrl} from "@/api/cockpit";
import PartFishParallax from "@/components/PartFishParallax";

export default {
  name: "LineupView",
  components: {PartFishParallax},
  props: {
    showTitle: Boolean,
  },
  data() {
    return {
      lineup: [],
    };
  },
  methods: {
    toggleExpandBox(ref) {
      //Expand Wrapper Element
      let expandWrapperEl = this.$refs[ref];
      if (typeof this.$refs[ref][0] !== "undefined") {
        expandWrapperEl = this.$refs[ref][0];
      }

      //Close opend Expand Wrapper
      let openArtistWrapper = document.querySelectorAll('.artist-wrapper.opened');
      openArtistWrapper.forEach(element => {
        if (element != expandWrapperEl) {
          element.classList.remove('opened');
        }
      });

      //open clicked wrapper
      setTimeout(() => {
        expandWrapperEl.classList.toggle('opened');
      }, 0);

      //scroll Into View
      setTimeout(() => {
        let elementPosition = expandWrapperEl.getBoundingClientRect().top + window.pageYOffset;
        // An die Ziel-Position scrollen
        window.scrollTo({
          top: elementPosition - 150,
          behavior: 'smooth'
        });
      }, 500);
    },
    getArtistImageUrl(image) {
      return cpFullStorageUrl(image);
    },
    getWrittenDay(dateString, withYear) {
      let date = new Date(dateString);

      // Definiere die Wochentage und Monate auf Deutsch
      let days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
      let months = [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
      ];

      // Holen den Tag und Monat
      let dayName = days[date.getDay()];
      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();

      // Formatieren des Datums
      return withYear ? `${dayName} ${day}. ${month} ${year}` : `${dayName} ${day}. ${month}`;
    },
    getWrittenVenue(venue) {
      if (typeof venue[0] !== "undefined") {
        return venue[0]
      }
      return '';
    },

  },
  computed: {
    getLineupByDate() {
      return this.lineup.reduce((acc, currentItem) => {
        const date = currentItem.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(currentItem);
        return acc;
      }, {});
    },
    getLineupByDateSorted() {
      const lineup = this.getLineupByDate
      const sorted = {};
      for (const date in lineup) {
        sorted[date] = [...lineup[date]].sort((a, b) => {
          return a.playTime.localeCompare(b.playTime);
        });
      }
      return sorted;
    },


  },
  created() {
    cpGetContentItems('lineup').then(data => {
      this.lineup = data;
    });

  },
}
</script>

<style scoped>
.artist-image-wrapper {
  display: flex;
  justify-content: center;
}

.artist-image {
  max-width: 700px;
  width: 100%;
}
</style>
