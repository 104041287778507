import axios from "axios";

// let cockpitApiUrlBase = 'https://backend.benigeier.ch:8890/api/' //dev
let cockpitApiUrlBase = 'https://fidmbackend.benigeier.ch/api/'
// let cockpitUploadPath = 'https://backend.benigeier.ch:8890/storage/uploads'; //dev
let cockpitUploadPath = 'https://fidmbackend.benigeier.ch/storage/uploads';
let cockpitApiKey = 'API-4ba809420a9f87bd1406a7d27f9b96bc8490e686'

/**
 * Get Content Item via Model ID (for Singletons)
 * @param model
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function cpGetContentItem(model) {
    return axios
        .get(cockpitApiUrlBase + 'content/item/' + model, {
            headers: {
                'Authorization': 'Bearer ' + cockpitApiKey
            }
        })
        .then(response => {
            if (typeof response.data !== "undefined") {
                return response.data
            }
            return false;
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });
}

/**
 * Get Content Items via Model ID (for Lists / Collections)
 * @param model
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function cpGetContentItems(model) {
    return axios
        .get(cockpitApiUrlBase + 'content/items/' + model, {
            headers: {
                'Authorization': 'Bearer ' + cockpitApiKey
            }
        })
        .then(response => {
            if (typeof response.data !== "undefined") {
                return response.data
            }
            return false;
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });
}

/**
 * Get Content Tree via Model ID (for Lists / TRees)
 * @param model
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function cpGetContentTree(model) {
    return axios
        .get(cockpitApiUrlBase + 'content/tree/' + model, {
            headers: {
                'Authorization': 'Bearer ' + cockpitApiKey
            }
        })
        .then(response => {
            if (typeof response.data !== "undefined") {
                return response.data
            }
            return false;
        })
        .catch(error => {
            // Handle error
            console.error(error);
        });
}

/**
 * make full Storage URL with path String
 * @param url
 * @returns {string}
 */
export function cpFullStorageUrl(url) {
    return cockpitUploadPath + url;
}
