<template>
  <h2 class="section-title" v-html="this.title" />
  <div class="container">
    <div class="content text" v-if="this.text">
      <div v-html="this.text"/>
         </div>
  </div>
  <div class="day-wrapper expand-wrapper" v-for="(day, d) in getLineupByDateSorted" :key="d"
       :ref="getWrittenDay(d, false)">
    <div class="expand-title" v-html="getWrittenDay(d, true)"
         @click="toggleExpandBox(getWrittenDay(d, false))"></div>
    <div class="container-fluid">
      <div class="expand-content">
        <div class="lineup-wrapper" v-for="(artist, a) in day" :key="a" :ref="artist.name">
          <div class="lineup-info">
            <div class="lineup-time" v-html="artist.playTime"/>
            •
            <div class="lineup-venue" v-html="artist.venue" />
          </div>
          <div class="lineup-title" v-html="artist.name" />
        </div>
      </div>
    </div>
  </div>

  <div class="ticketlink">
  <a target="_blank" :href="getPdfLink">{{this.downloadText}}</a>
  </div>

  <PartFishParallax/>
</template>

<script>
import {cpFullStorageUrl, cpGetContentItem, cpGetContentItems} from "@/api/cockpit";
import PartFishParallax from "@/components/PartFishParallax";

export default {
  name: "TimetableFancyView",
  components: {PartFishParallax},
  props: {
    showTitle: Boolean,
  },
  data() {
    return {
      lineup: [],
      title: '',
      text: '',
      pdf:'',
      downloadText: '',
    };
  },
  methods: {
    toggleExpandBox(ref) {
      //Expand Wrapper Element
      let expandWrapperEl = this.$refs[ref];
      if (typeof this.$refs[ref][0] !== "undefined") {
        expandWrapperEl = this.$refs[ref][0];
      }

      //Close opend Expand Wrapper
      let openArtistWrapper = document.querySelectorAll('.expand-wrapper.opened');
      openArtistWrapper.forEach(element => {
        if (element != expandWrapperEl) {
          element.classList.remove('opened');
        }
      });

      //open clicked wrapper
      setTimeout(() => {
        expandWrapperEl.classList.toggle('opened');
      }, 0);

      //scroll Into View
      setTimeout(() => {
        let elementPosition = expandWrapperEl.getBoundingClientRect().top + window.pageYOffset;
        // An die Ziel-Position scrollen
        window.scrollTo({
          top: elementPosition - 150,
          behavior: 'smooth'
        });
      }, 500);
    },
    getArtistImageUrl(image) {
      return cpFullStorageUrl(image);
    },
    getWrittenDay(dateString, withYear) {
      let date = new Date(dateString);

      // Definiere die Wochentage und Monate auf Deutsch
      let days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
      let months = [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
      ];

      // Holen den Tag und Monat
      let dayName = days[date.getDay()];
      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();

      // Formatieren des Datums
      return withYear ? `${dayName} ${day}. ${month} ${year}` : `${dayName} ${day}. ${month}`;
    },
    getWrittenVenue(venue) {
      if (typeof venue[0] !== "undefined") {
        return venue[0]
      }
      return '';
    },

  },
  computed: {
    getPdfLink() {
      return cpFullStorageUrl(this.pdf.path);
    },
    getLineupByDate() {
      return this.lineup.reduce((acc, currentItem) => {
        const date = currentItem.date;
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(currentItem);


        return acc;
      }, {});
    },
    getLineupByDateSorted() {
      const lineup = this.getLineupByDate


      //Add Geländeöffnungszeiten as Array Index
      if (typeof lineup["2024-08-09"] !== "undefined") {
        let openingFriday = {"date": "2024-08-09", "name": "Festivalbeginn", "playTime": "17:00","venue":"Gelände"}
        let openingSaturday = {"date": "2024-08-10", "name": "Geländeöffnung", "playTime": "15:00","venue":"Gelände"}

        lineup["2024-08-09"].push(openingFriday);
        lineup["2024-08-10"].push(openingSaturday);
        // console.log(lineup["2024-08-09"])

      }
      const sorted = {};
      for (const date in lineup) {
        sorted[date] = [...lineup[date]].sort((a, b) => {
          return a.playTime.localeCompare(b.playTime);
        });
      }
      return sorted;
    },
  },
  created() {
    cpGetContentItems('lineup').then(data => {
      this.lineup = data;
    });
    cpGetContentItem(this.$route.name).then(data => {
      this.title = data.title;
      this.text = data.text;
      this.pdf = data.pdf;
      this.downloadText = data.downloadtext;
    });
  },
}
</script>

<style scoped>
.lineup-wrapper {
  font-family: 'BebasNeue-Bold';
  text-align: center;
  color: var(--color-orange);
}

.lineup-wrapper:not(:last-child) {
  margin-bottom: 0.5rem;
}

.lineup-info {
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  font-size: 1.2rem;
}



</style>