<template>
  <section class="wrapper">
    <div class="ticketlink">
      <a v-bind:href="this.$store.getters.getTicketLink" target="_blank">Jetzt Ticket sichern</a>
    </div>
  </section>
</template>

<script>

export default {
  name: "SectionTicketlink",
  data() {
    return {
    };
  },
  computed: {

  },
  created() {


  },
}
</script>

<style scoped>
/*.ticketlink {*/
/*  font-size: 2rem;*/
/*  line-height: 1;*/
/*  !*margin-bottom: -15px;*!*/
/*  padding: var(--main-margin);*/
/*  padding-top: 1rem;*/
/*  font-family: 'BebasNeue-Bold';*/
/*  text-align: center;*/
/*  overflow: hidden;*/
/*}*/
/*.ticketlink a {*/
/*  color: var(--color-grey);*/
/*  transition: all .9s ease;*/
/*  text-decoration: none;*/
/*}*/
.ticketlink a:hover {
  /*color: var(--color-blue);*/
  /*letter-spacing: var(--letter-spacing);*/
}

/*@media (max-width: 992px) {*/
/*  .ticketlink {*/
/*    margin-bottom: -8px;*/
/*    font-size: 4rem;*/
/*  }*/
/*  a:hover, .ticketlink:hover {*/
/*    letter-spacing: normal;*/
/*  }*/
/*}*/

/*@media (max-width: 576px) {*/
/*  .ticketlink {*/
/*    margin-bottom: -6px;*/
/*    font-size: 3rem;*/
/*  }*/
/*  a:hover, .ticketlink:hover {*/
/*    letter-spacing: normal;*/
/*  }*/
/*}*/


</style>
