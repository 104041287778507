import {createStore} from "vuex";

export const store = createStore({
    state() {
        return {
            globalAttributes: {
                color1: '',
                color2: '',
                color3: '',
                color4: '',
                backgroundImage: '',
            },
            mainMenu: [],
            footerMenu: [],
            ticketLink: '',
            // socialmediaLinks: [],
        }
    },
    getters: {
        getGlobalAttributeBackgroundImage(state) {
          return state.globalAttributes.backgroundImage;
        },
        getMainMenu(state) {
            return state.mainMenu;
        },
        getFooterMenu(state) {
            return state.footerMenu;
        },
        getTicketLink(state) {
            return state.ticketLink;
        }
        // getSocialmediaLinks(state) {
        //     return state.socialmediaLinks;
        // }
    },

    actions: {   },
    mutations: {
        setGlobalAttributeColor(state, color) {
            state.globalAttributes['color' + color.key] = color.hex;
        },
        setGlobalAttributeBackgroundImage(state, image) {
            state.globalAttributes.backgroundImage = image;
        },
        setMainMenu(state, data) {
          state.mainMenu = data;
        },
        setFooterMenu(state, data) {
            state.footerMenu = data;
        },
        setTicketLink(state, data) {
            state.ticketLink = data;
        }
        // setSocialmediaLinks(state, smLinks) {
        //     state.socialmediaLinks = smLinks;
        // }
    }
});
