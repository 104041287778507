<template>
  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 556.3 200">

    <path class="cls-1" d="M256,2.4c1,1.5,1.2,3.4.5,5.3l-75.1,186.8c-1.3,3-5.1,5.6-8.3,5.6h-26.6c-3.5,0-6.9-2.8-8-5.5L63.7,7.7c-.9-1.7-.9-3.7.1-5.3.9-1.5,2.7-2.4,4.7-2.4h26.6c3.5,0,7.2,2.5,8.3,5.6l56.5,140.5L216.5,5.6c1.2-3.2,4.7-5.6,8.1-5.6h26.6c2.1,0,3.8.9,4.9,2.4"/>
    <path class="cls-1" d="M44.7,5.6C43.5,2.4,40.1,0,36.6,0H10C7.9,0,6.2.9,5.1,2.4c-1,1.5-1.2,3.4-.5,5.3l41,102H6.9c-3.8,0-6.9,3-6.9,6.6v24.2c0,3.7,3.2,6.9,6.9,6.9h53.9l18.9,47c1.3,3,5.1,5.5,8.3,5.5h26.6c2,0,3.7-.9,4.7-2.4,1-1.5,1.2-3.5.4-5.3L44.7,5.6Z"/>
    <path class="cls-1" d="M200.3,192.3c-.8,1.8-.6,3.8.4,5.3,1,1.5,2.7,2.4,4.7,2.4h26.6c3.2,0,7-2.5,8.3-5.5l18.9-47h53.9c3.7,0,6.9-3.2,6.9-6.9v-24.2c0-3.7-3.1-6.6-6.9-6.6h-38.7L315.4,7.7c.7-1.9.6-3.8-.5-5.3C313.8.9,312.1,0,310,0h-26.6c-3.4,0-6.8,2.4-8.1,5.6l-75,186.7Z"/>
    <g>
      <path class="cls-1" d="M392.7,4h7.6l12.7,36h-7.6l-2.4-7.7h-13l-2.4,7.7h-7.4l12.5-36ZM391.6,26.8h9.5l-3.2-10.4c-.6-1.9-1.5-5.5-1.5-5.5h-.1s-.9,3.6-1.5,5.5l-3.2,10.4Z"/>
      <path class="cls-1" d="M431.9,37.1h-.1c-1.3,2-3.2,3.5-7.6,3.5s-9-2.8-9-7.9,4.6-7.5,10.4-8.3c4.3-.6,6.2-1,6.2-2.9s-1.5-3.1-4.3-3.1-4.8,1.2-5,3.6h-6.1c.2-4.5,3.6-8.5,11.1-8.5s10.9,3.5,10.9,9.5v13.1c0,2,.3,3.1.9,3.6v.3h-6.6c-.4-.5-.7-1.7-.8-2.9ZM431.9,30.9v-3.9c-1.2.7-3.1,1.1-4.8,1.5-3.6.8-5.3,1.6-5.3,4s1.6,3.3,4,3.3c3.9,0,6.1-2.4,6.1-4.9Z"/>
      <path class="cls-1" d="M450.3,18.3h.2c1.6-2.9,3.3-4.4,6.3-4.4s1.2,0,1.6.2v6h-.2c-4.5-.5-7.7,1.9-7.7,7.4v12.6h-6.8V14.2h6.6v4.1Z"/>
      <path class="cls-1" d="M460.9,40.8h6.8c.6,1.6,2,2.9,5.2,2.9s5.7-1.9,5.7-5.4v-2.8h-.2c-1.5,1.7-3.5,3-6.7,3-5.7,0-11.4-4.5-11.4-12.3s4.6-12.6,11.2-12.6,5.6,1.3,7.1,3.4h.1v-2.7h6.6v23.9c0,3.6-1.2,6.1-3.1,7.9-2.2,2-5.5,2.9-9.3,2.9-7,0-11.4-3-12.1-8.1ZM479,26c0-3.8-2-7-6.1-7s-5.8,2.7-5.8,7,2.3,6.9,5.8,6.9,6.1-3.2,6.1-7Z"/>
      <path class="cls-1" d="M506.3,37.1h-.1c-1.3,2-3.2,3.5-7.6,3.5s-9-2.8-9-7.9,4.6-7.5,10.4-8.3c4.3-.6,6.2-1,6.2-2.9s-1.5-3.1-4.3-3.1-4.8,1.2-5,3.6h-6.1c.2-4.5,3.6-8.5,11.1-8.5s10.9,3.5,10.9,9.5v13.1c0,2,.3,3.1.9,3.6v.3h-6.6c-.4-.5-.7-1.7-.8-2.9ZM506.3,30.9v-3.9c-1.2.7-3.1,1.1-4.8,1.5-3.6.8-5.3,1.6-5.3,4s1.6,3.3,4,3.3c3.9,0,6.1-2.4,6.1-4.9Z"/>
      <path class="cls-1" d="M534.4,40v-3h-.2c-1.8,2.4-3.8,3.7-7.4,3.7-5.7,0-9-3.7-9-9.3V14.2h6.8v16.1c0,3,1.4,4.7,4.3,4.7s5.2-2.4,5.2-5.8v-14.9h6.8v25.8h-6.6Z"/>
    </g>
    <g>
      <path class="cls-1" d="M380.1,56h7.7l6.3,20.8c.7,2.4,1.6,5.7,1.6,5.7h.1s.9-3.4,1.6-5.7l6.3-20.8h7.4l-12.1,36h-6.6l-12.1-36Z"/>
      <path class="cls-1" d="M409.5,79.1c0-7.7,5.2-13.6,12.7-13.6s12.7,6.3,12.7,15.6h-18.7c.5,4,2.7,6.4,6.6,6.4s4.2-1.2,5-3.2h6.7c-1,4.6-5,8.5-11.7,8.5s-13.4-6-13.4-13.7ZM416.4,76.3h11.5c-.2-3.3-2.4-5.5-5.5-5.5s-5.4,2.2-6,5.5Z"/>
      <path class="cls-1" d="M445.8,70.3h.2c1.6-2.9,3.3-4.4,6.3-4.4s1.2,0,1.6.2v6h-.2c-4.5-.5-7.7,1.9-7.7,7.4v12.6h-6.8v-25.8h6.6v4.1Z"/>
      <path class="cls-1" d="M467.6,80.2l-3,3.1v8.7h-6.8v-36h6.8v19.9l9.2-9.8h8.2l-9.5,9.8,10.7,16h-8.3l-7.2-11.8Z"/>
      <path class="cls-1" d="M483.3,79.1c0-7.7,5.2-13.6,12.7-13.6s12.7,6.3,12.7,15.6h-18.7c.5,4,2.7,6.4,6.6,6.4s4.2-1.2,5-3.2h6.7c-1,4.6-5,8.5-11.7,8.5s-13.4-6-13.4-13.7ZM490.2,76.3h11.5c-.2-3.3-2.4-5.5-5.5-5.5s-5.4,2.2-6,5.5Z"/>
      <path class="cls-1" d="M519.8,69.5h.2c1.9-2.7,4-4.1,7.5-4.1,5.3,0,8.9,4,8.9,9.7v16.9h-6.8v-15.9c0-2.8-1.6-4.7-4.5-4.7s-5.2,2.4-5.2,5.9v14.6h-6.8v-36h6.8v13.5Z"/>
      <path class="cls-1" d="M548.2,70.3h.2c1.6-2.9,3.3-4.4,6.3-4.4s1.2,0,1.6.2v6h-.2c-4.5-.5-7.7,1.9-7.7,7.4v12.6h-6.8v-25.8h6.6v4.1Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgLogoAva"
}
</script>

<style scoped>
.cls-1 {
  /*fill: #e9e9e9;*/
  stroke-width: 0px;
}
</style>
