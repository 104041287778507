<template>
<svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 555.3 200">
  <path class="cls-1" d="M73.38,3.61C31.08,15.27,0,54,0,100c0,27.14,10.82,51.73,28.37,69.75L83.53,26.36l-10.15-22.76h0Z"/><polygon class="cls-1" points="95.05 52.17 74.12 106.58 119.33 106.58 95.05 52.17"/><path class="cls-1" d="M100,0c-2.09,0-4.16.09-6.22.21l47.48,106.36h58.5c.14-2.18.24-4.36.24-6.58,0-55.22-44.77-99.99-100-99.99Z"/><path class="cls-1" d="M161.95,152.92h-21.93l-11.75-26.33h-61.84l-21.8,56.67c15.86,10.57,34.9,16.74,55.39,16.74,46.02,0,84.75-31.09,96.4-73.41h-46.21l11.75,26.33h0Z"/><polygon class="cls-1" points="298.83 123.77 298.63 123.77 285.34 86.88 267.89 86.88 254.6 123.77 254.4 123.77 242.9 86.88 222.07 86.88 241.51 137.46 266.89 137.46 276.51 110.09 276.71 110.09 286.33 137.46 311.72 137.46 331.16 86.88 310.33 86.88 298.83 123.77"/><path class="cls-1" d="M375.58,86.09h-13.29c-13.89,0-23.4,4.16-23.4,23.3v4.86c0,21.62,13.29,24,25.88,24h14.28c13.79,0,25.09-2.78,25.09-15.47v-2.18h-17.85c0,2.98-.5,5.75-10.81,5.75h-5.85c-7.93,0-11.7-1.49-11.7-6.84v-3.08h46.21v-8.53c0-17.55-8.33-21.82-28.56-21.82h0ZM357.93,105.13c0-6.05,1.49-7.14,13.89-7.14,11.11,0,13.88,1.09,13.88,7.14h-27.77Z"/><rect class="cls-1" x="418.92" y="65.66" width="19.04" height="71.8"/><rect class="cls-1" x="456.21" y="65.66" width="19.04" height="71.8"/><path class="cls-1" d="M555.27,116.43v-8.53c0-17.55-8.33-21.82-28.56-21.82h-13.29c-13.89,0-23.41,4.16-23.41,23.3v4.86c0,21.62,13.29,24,25.88,24h14.28c13.79,0,25.09-2.78,25.09-15.47v-2.18h-17.85c0,2.98-.5,5.75-10.81,5.75h-5.85c-7.93,0-11.7-1.49-11.7-6.84v-3.08h46.22ZM522.95,97.99c11.1,0,13.88,1.09,13.88,7.14h-27.77c0-6.05,1.49-7.14,13.89-7.14Z"/>
</svg>
</template>

<script>
export default {
  name: "SvgLogoAwelle"
}
</script>

<style scoped>
.cls-1{stroke-width:0px;}
</style>
