<template>
  <h2 class="section-title" v-html="this.title" />
  <div class="container">
    <div class="content text" v-html="this.text"/>

  </div>
</template>

<script>
import {cpGetContentItem} from "@/api/cockpit";

export default {
  name: "TextView",
  data() {
    return {
      title: '',
      text: '',
    };
  },
  methods: {},
  computed: {},
  created() {
    cpGetContentItem(this.$route.name).then(data => {
      this.title = data.title;
      this.text = data.text;
    });
  },
}
</script>

<style scoped>

</style>
