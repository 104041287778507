<template>
  <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <path class="cls-1" d="M100,0c-27.16,0-30.57.12-41.23.6-10.65.48-17.92,2.17-24.28,4.65-6.58,2.56-12.15,5.98-17.71,11.53-5.56,5.56-8.98,11.14-11.53,17.71-2.47,6.36-4.16,13.63-4.65,24.28-.49,10.67-.6,14.07-.6,41.23s.12,30.57.6,41.23c.48,10.64,2.17,17.91,4.65,24.27,2.56,6.58,5.98,12.15,11.53,17.71,5.56,5.56,11.14,8.98,17.71,11.53,6.36,2.47,13.63,4.16,24.28,4.65,10.66.49,14.07.6,41.23.6s30.57-.11,41.23-.6c10.65-.49,17.92-2.18,24.28-4.65,6.58-2.55,12.15-5.98,17.71-11.53,5.56-5.56,8.98-11.14,11.53-17.71,2.47-6.36,4.16-13.63,4.65-24.27.49-10.67.6-14.07.6-41.23s-.12-30.56-.6-41.23c-.48-10.65-2.17-17.92-4.65-24.28-2.56-6.58-5.98-12.15-11.53-17.71-5.56-5.56-11.14-8.98-17.71-11.53-6.36-2.47-13.63-4.16-24.28-4.65-10.66-.49-14.07-.6-41.23-.6M100,18.02c26.7,0,29.86.1,40.41.58,9.75.45,15.04,2.07,18.57,3.44,4.67,1.81,8,3.98,11.5,7.48,3.5,3.5,5.67,6.83,7.48,11.5,1.37,3.53,3,8.82,3.44,18.57.48,10.54.58,13.7.58,40.41s-.1,29.86-.58,40.41c-.44,9.75-2.07,15.04-3.44,18.57-1.81,4.67-3.98,8-7.48,11.5-3.5,3.5-6.83,5.67-11.5,7.48-3.53,1.37-8.82,3-18.57,3.44-10.54.48-13.71.58-40.41.58s-29.87-.1-40.41-.58c-9.75-.44-15.04-2.07-18.57-3.44-4.67-1.81-8-3.98-11.5-7.48-3.5-3.5-5.67-6.83-7.48-11.5-1.37-3.53-3-8.82-3.44-18.57-.48-10.55-.58-13.71-.58-40.41s.1-29.86.58-40.41c.44-9.75,2.07-15.05,3.44-18.57,1.81-4.67,3.98-8,7.48-11.5,3.5-3.5,6.83-5.67,11.5-7.48,3.53-1.37,8.82-3,18.57-3.44,10.55-.48,13.71-.58,40.41-.58"/><path class="cls-1" d="M100,133.33c-18.41,0-33.33-14.92-33.33-33.33s14.92-33.33,33.33-33.33,33.33,14.92,33.33,33.33-14.92,33.33-33.33,33.33M100,48.65c-28.36,0-51.35,22.99-51.35,51.35s22.99,51.35,51.35,51.35,51.35-22.99,51.35-51.35-22.99-51.35-51.35-51.35"/><path class="cls-1" d="M165.38,46.62c0,6.63-5.37,12-12,12s-12-5.37-12-12,5.37-12,12-12,12,5.37,12,12"/></svg>
</template>

<script>
export default {
  name: "svgIconInstagram"
}
</script>

<style scoped>
/*.cls-1{fill:#e9e9e9;stroke-width:0px;}*/
</style>
